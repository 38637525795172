import Modal from './modal.js';

class CardOffer extends Modal {
  constructor(offers, cardsData, buttons, modals) {
    super(buttons, modals);
    this.offers = offers;
    this.cardsData = cardsData;
  }

  init() {
    this.bindEvents();
    this.setupEvents();
  }

  bindEvents() {
    this.createCard = this.createCard.bind(this);
    this.openLayout = this.openLayout.bind(this);
  }

  setupEvents() {
    if (this.cardsData) {
      this.cardsData.forEach((element) => {
        const offerCard = this.createCard(element);
        this.offers.appendChild(offerCard);
      });
    }

    this.cardButton = document.querySelectorAll('.offer__more-btn');
    this.cardButton.forEach((button) => {
      button.addEventListener('click', (e) => {
        this.openModal(button);
      });
    });
  }
  createCard(element) {
    const {
      title,
      id,
      description,
      moreBtn,
      link,
      image,
      altImage,
      modalName,
    } = element;
    const card = document.createElement('li');
    card.classList.add('offers__item', 'lazy', 'offer__card');
    card.setAttribute('id', id);
    const cardContent = `
        <div class="offer__card--img-container">
            <img
            class="offer__card--img"
            src="${image}"
            alt="${altImage}"
            />
        </div>
        <h2 class="offer__card--title">${title}</h2>
        <div class="offer__card--text">
        <p>${description}</p>
        </div>
        ${
          moreBtn
            ? `
        <div class='open-btn offer__more-btn' ${
          modalName ? `id=${modalName}` : ''
        } >
            ${
              link
                ? '<a href="https://github.com/KamilaDyn" target="_blank">Mój github <img src="./assets/click.svg" alt="enter arrow" /></a>'
                : '<p>czytaj więcej</p><img src="./assets/click.svg" alt="enter arrow" />'
            }
       
        </div>`
            : ''
        }
        `;

    card.innerHTML = cardContent;
    return card;
  }

  openLayout(id) {
    this.openModal(id);
  }
}

export default CardOffer;
