const offerData = '../data/offerCards.json';
const environmentData = '../data/environments.json';
const cardsPriceOffer = '../data/priceCards.json';

async function fetchOfferDataCard() {
  try {
    const response = await fetch(offerData);
    const dataArray = await response.json();
    return dataArray;
  } catch (error) {
    console.error(error);
  }
}
async function fetchOfferPrice() {
  try {
    const response = await fetch(cardsPriceOffer);
    const dataArray = await response.json();
    return dataArray;
  } catch (error) {
    console.error(error);
  }
}

async function fetchEnvironmentData() {
  try {
    const response = await fetch(environmentData);
    const dataArray = await response.json();
    return dataArray;
  } catch (error) {
    console.error(error);
    console.error(error.message);
  }
}

export { fetchOfferDataCard, fetchEnvironmentData, fetchOfferPrice };
