class MobileMenu {
  constructor(navList, burgerMenu, navLinks) {
    this.navList = navList;
    this.burgerMenu = burgerMenu;
    this.navLinks = navLinks;
  }

  init() {
    this.bindEvents();
    this.setEvents();
  }
  setEvents() {
    this.navLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        this.handleActiveLink(e);
      });
    });
    this.burgerMenu.addEventListener('click', (e) => {
      this.toggleMobileMenu(e);
    });
  }
  bindEvents() {
    this.getFragmentIdentifier = this.getFragmentIdentifier.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.handleActiveLink = this.handleActiveLink.bind(this);
  }
  getFragmentIdentifier(url) {
    const parts = url.split('#');
    return parts[1];
  }

  handleRedirection(id) {
    const fragmentIdentifier = this.getFragmentIdentifier(id);
    if (fragmentIdentifier) {
      window.location.href = `/#${fragmentIdentifier}`;
    } else {
      window.location.href = '/';
    }
  }

  handleActiveLink(e) {
    const id = e.target.getAttribute('href');
    const link = e.target;
    if (this.burgerMenu.classList.contains('hamburger--active')) {
      e.preventDefault();
      const siblings = link
        .closest('.header__nav')
        .querySelectorAll('.nav--link');
      siblings.forEach((el) => {
        if (el !== link) el.classList.remove('nav--link--active');
      });
      link.classList.add('nav--link--active');

      this.toggleMobileMenu();
    }
    const cleanedSelector = id.replace(/^\/|\.html$/g, '');

    const section = document.querySelector(cleanedSelector);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.handleRedirection(id);
    }
  }
  toggleMobileMenu() {
    this.burgerMenu.classList.toggle('hamburger--active');
    this.navList.classList.toggle('header__nav--active');
  }
}
export default MobileMenu;
