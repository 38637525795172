class ScrollDownButton {
  constructor(button, toSection) {
    this.toSection = toSection;
    this.button = button;
  }

  init() {
    this.setEvents();
  }
  setEvents() {
    this.button.addEventListener('click', () => {
      this.toSection.scrollIntoView({ behavior: 'smooth' });
    });
  }
}

export default ScrollDownButton;
