class CookieBanner {
  constructor(consentBox, acceptBtn) {
    this.consentBox = consentBox;
    this.acceptBtn = acceptBtn;
  }
  init() {
    this.binEvents();
    this.setEvents();
  }

  binEvents() {
    this.setCookie = this.setCookie.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.cookieMessage = this.cookieMessage.bind(this);
  }

  setEvents() {
    this.acceptBtn.addEventListener('click', () => {
      this.consentBox.style.display = 'none';
      this.setCookie('jdc_consent', true, 30);
    });
    window.addEventListener('load', this.cookieMessage());
  }

  setCookie = (cName, cValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
  };
  getCookie = (cName) => {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let value;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) value = val.substring(name.length);
    });
    return value;
  };
  cookieMessage = () => {
    if (!this.getCookie('jdc_consent')) {
      this.consentBox.style.display = 'block';
    }
  };
}

export default CookieBanner;
