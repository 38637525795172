class TechnologyList {
  constructor(list) {
    this.list = list;
  }
  init() {
    this.bindEvent();
    this.setEvents();
  }
  bindEvent() {
    this.cloneTechnology = this.cloneTechnology.bind(this);
  }
  setEvents() {
    const totalItems = this.list.children.length;
    document.documentElement.style.setProperty('---total-items', totalItems);
    this.cloneTechnology(totalItems);
  }

  cloneTechnology(totalItems) {
    for (let i = 0; i < totalItems; i++) {
      this.list.appendChild(this.list.children[i].cloneNode(true));
    }
  }
}

export default TechnologyList;
