class Modal {
  constructor(buttons, modals) {
    this.buttons = buttons;
    this.isModalOpened = false;
    this.modals = modals;
  }
  init() {
    this.setEvents();
  }

  setEvents() {
    document.body.addEventListener('click', (e) => {
      this.clickOverflow(e);
    });
    this.buttons.forEach((button) => {
      button.addEventListener('click', () => {
        this.openModal(button);
      });
    });
  }
  openModalFunction = () => {
    this.isModalOpened = true;
  };
  displayModalContent = (element) => {
    for (const modal of this.modals) {
      if (element === modal.modalName) {
        return modal.modal;
      }
    }
    return null;
  };
  openModal(button) {
    const buttonId = button?.id;
    if (buttonId) {
      const displayModal = this.displayModalContent(buttonId);
      const closestSection = button.closest('section');
      const newDiv = document.createElement('div');
      newDiv.classList.add('modal');
      newDiv.id = `opened-${buttonId}`;
      const overflow = document.createElement('div');
      overflow.classList.add('overflow');
      document.querySelector('body').append(overflow);
      newDiv.innerHTML = displayModal;
      document.querySelector('body').style.overflow = 'hidden';
      closestSection.appendChild(newDiv);
      this.isModalOpened = true;
      this.openModalFunction();
      const closeBtn = document.querySelector('#closeBtn');
      if (closeBtn) {
        closeBtn.addEventListener('click', () => {
          this.closeModal(buttonId);
        });
      }
    }
  }
  closeModal = (buttonId) => {
    const modal = document.querySelector(`#opened-${buttonId}`);
    const overflow = document.querySelector('.overflow');

    if (modal) {
      modal.remove();
    }

    if (overflow) {
      overflow.remove();
    }

    document.querySelector('body').style.overflow = 'auto';
    this.isModalOpened = false;
  };

  clickOverflow(e) {
    let targetElement = e.target;

    while (targetElement !== null) {
      if (targetElement.classList.contains('overflow')) {
        const modal = document.querySelector('.modal');
        modal.remove();
        targetElement.remove();
        document.querySelector('body').style.overflow = 'auto';
        break;
      }

      targetElement = targetElement.parentElement;
    }
  }
}

export default Modal;
