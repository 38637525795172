class ModeButton {
  constructor(toggleModalCheckbox, toggleModeLabel) {
    this.toggleModalCheckbox = toggleModalCheckbox;
    this.toggleModeLabel = toggleModeLabel;
  }

  init() {
    this.bindEvents();
    this.setupEvents();
    this.handleMode();
  }

  setupEvents() {
    this.toggleModeLabel.addEventListener('click', () =>
      this.handleThemeMode()
    );
  }

  bindEvents() {
    this.getStoredTheme = this.getStoredTheme.bind(this);
    this.setStoredTheme = this.setStoredTheme.bind(this);
    this.handleThemeMode = this.handleThemeMode.bind(this);
    this.setTheme = this.setTheme.bind(this);
  }
  getStoredTheme = () => localStorage.getItem('theme');
  setStoredTheme = (theme) => localStorage.setItem('theme', theme);
  setTheme(theme) {
    if (theme === 'auto' || theme === 'light') {
      this.setStoredTheme(theme);
      document.documentElement.setAttribute('data-theme-mode', theme);
      this.toggleModeLabel.setAttribute('title', 'włącz tryb ciemny');
      this.toggleModalCheckbox.ariaChecked = true;
    } else {
      this.setStoredTheme('dark');
      document.documentElement.setAttribute('data-theme-mode', 'dark');
      this.toggleModeLabel.setAttribute('title', 'włącz tryb jasny');
      this.toggleModalCheckbox.ariaChecked = false;
    }
  }

  handleThemeMode() {
    const defaultTheme = document
      .querySelector('html')
      .getAttribute('data-theme-mode');
    if (defaultTheme === 'light') {
      this.setTheme('dark');
    } else {
      this.setTheme('light');
    }
  }

  handleMode() {
    const currentTheme = this.getStoredTheme();

    if (currentTheme) {
      document.documentElement.setAttribute('data-theme-mode', currentTheme);
    }
    if (currentTheme === 'dark') {
      this.toggleModalCheckbox.ariaChecked = false;
      this.toggleModeLabel.setAttribute('title', 'włącz tryb jasny');
    } else {
      this.toggleModalCheckbox.ariaChecked = true;
      this.toggleModeLabel.setAttribute('title', 'włącz tryb ciemny');
    }
  }
}

export default ModeButton;
