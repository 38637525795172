class Environments {
  constructor(stack, environmentData) {
    this.stack = stack;
    this.environmentData = environmentData;
  }
  init() {
    this.setupEvents();
  }

  setupEvents() {
    const tabContainer = document.querySelector('.stack__tab--container');

    this.environmentData?.forEach((element) => {
      const environmentTab = this.displayStackEnvironment(element);
      this.stack.appendChild(environmentTab);
    });
    tabContainer.addEventListener('click', (e) => {
      this.changeTab(e);
    });
  }
  displayStackEnvironment(element) {
    const { id, name, stack, image, altImage } = element;
    const card = document.createElement('li');
    card.classList.add('stack__content', `stack__content--${id}`, 'lazy');
    card.setAttribute('id', id);
    const cardContent = `<div class="stack__img">
            <img
                loading="lazy"
                src="${image}"
                alt="${altImage}"
            />
        </div>
        <div class="stack__text">
            <h3 class="stack__text--header">${name}</h3>
            <ul class="stack__text--list">
            ${stack.map((value, index) => `<li>${value}</li>`).join('')}
            </ul>
        </div>
        `;

    const firstChild = document.querySelector('.stack__list :first-child');

    if (firstChild) {
      firstChild.classList.add('stack__content--active');
    }
    card.innerHTML = cardContent;
    return card;
  }

  changeTab(e) {
    const tabs = document.querySelectorAll('.stack__tab'),
      tabsContent = document.querySelectorAll('.stack__content');

    const clicked = e.target.closest('.stack__tab');

    if (!clicked) return;
    tabs.forEach((tab) => tab.classList.remove('stack__tab--active'));
    tabsContent.forEach((content) =>
      content.classList.remove('stack__content--active')
    );
    clicked.classList.add('stack__tab--active');
    document
      .querySelector(`.stack__content--${clicked.dataset.tab}`)
      .classList.add('stack__content--active');
  }
}

export default Environments;
