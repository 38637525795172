import '../styles/index.scss';

import { fetchOfferDataCard, fetchEnvironmentData } from './config.js';
import aboutMe from '../modals/modal-about-me.html';
import landingPageModal from '../modals/module-landing-page.html';
import wpPageModal from '../modals/module-wp-page.html';

import CardOffer from './offerCardList.js';
import Environments from './environments.js';
import LazyLoadObserver from './lazyLoading.js';
import Modal from './modal.js';
import ScrollButton from './scrollButton.js';
import ScrollDownButton from './scrollDownButton.js';
import CookieBanner from './cookiBanner.js';
import ModeButton from './modeButton.js';
import MobileMenu from './mobileMenu.js';
import TechnologyList from './technology.js';

const modals = [
  { modalName: 'modal-about-me', modal: aboutMe },
  { modalName: 'modal-landing-page', modal: landingPageModal },
  { modalName: 'modal-wp-page', modal: wpPageModal },
];

document.addEventListener('DOMContentLoaded', async function () {
  const offersList = document.querySelector('.offer__container'),
    stack = document.querySelector('.stack__list'),
    openModalBtn = document.querySelectorAll('.open-btn'),
    scrollBtn = document.getElementById('scrollBtn'),
    upBtn = document.getElementById('up-btn'),
    header = document.getElementById('header'),
    consentBox = document.querySelector('#consentBox'),
    acceptBtn = document.querySelector('.cookie-box__buttons--consentButton'),
    toggleModalCheckbox = document.querySelector('#page-mode-checkbox'),
    toggleModeLabel = document.querySelector('#page-mode-label'),
    navLinks = document.querySelectorAll('.nav--link'),
    burgerMenu = document.querySelector('#burger-menu'),
    navList = document.querySelector('#nav-list'),
    list = document.querySelector('#icons-list'),
    downBtn = document.querySelector('#scrollBtn'),
    aboutSection = document.querySelector('#about');
  const fetchOfferCardData = await fetchOfferDataCard();
  const fetchEnvironmentsData = await fetchEnvironmentData();
  const offersCard = new CardOffer(
    offersList,
    fetchOfferCardData,
    openModalBtn,
    modals
  );
  const environmentsTabs = new Environments(stack, fetchEnvironmentsData);
  const modal = new Modal(openModalBtn, modals);
  const lazyLoadObserver = new LazyLoadObserver('.lazy', {
    root: null,
    threshold: 0.15,
  });
  const scrollButton = new ScrollButton(scrollBtn, upBtn, header),
    cookieBanner = new CookieBanner(consentBox, acceptBtn),
    modeButton = new ModeButton(toggleModalCheckbox, toggleModeLabel),
    mobileMenu = new MobileMenu(navList, burgerMenu, navLinks),
    technologyList = new TechnologyList(list),
    scrollDownBtn = new ScrollDownButton(downBtn, aboutSection);

  lazyLoadObserver.init();
  offersCard.init();
  environmentsTabs.init();
  modal.init();
  scrollButton.init();
  cookieBanner.init();
  modeButton.init();
  mobileMenu.init();
  technologyList.init();
  scrollDownBtn.init();
});
