class ScrollButton {
  constructor(scrollBtn, upBtn, header) {
    this.upBtn = upBtn;
    this.scrollBtn = scrollBtn;
    this.header = header;
  }

  init() {
    this.bindEvent();
    this.setEvents();
    window.onscroll = this.handleScroll;
  }

  bindEvent() {
    this.handleScroll = this.handleScroll.bind(this);
  }

  setEvents() {
    this.upBtn.addEventListener('click', () => {
      this.header.scrollIntoView({ behavior: 'smooth' });
    });
  }

  handleScroll() {
    if (
      document.documentElement.scrollTop >=
      this.header.getBoundingClientRect().height
    ) {
      this.upBtn.classList.add('up-btn-show');
    } else {
      this.upBtn.classList.remove('up-btn-show');
    }
  }
}

export default ScrollButton;
